<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card title="Laporan Performa">
        <template slot="body">
          <form @submit.prevent="submitForm">
            <b-row v-show="isPusat">
              <b-col sm="12">
                <form-select-multiple :disabled="!isPusat||isRegional" ref="regionalSelect" @value-changed="regionalChange" url="v1/regional_select2" v-model="filter.id_regional" label="Regional"></form-select-multiple>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-select-multiple :filter-id="idBranchArray" ref="branchSelect" @value-changed="branchChange" :url="`v1/branch_select2?id_regional=${idRegionalToString}`" v-model="filter.id_branch" label="Cabang"></form-select-multiple>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-select-multiple ref="userSelect" @value-changed="userChange" url="v1/user_select2" v-model="filter.id_user" label="Pengguna"></form-select-multiple>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group label="Tanggal Mulai" label-for="startDate">
                  <b-form-datepicker id="startDate" v-model="filter.date_1" locale="id-ID" :max="maxDate"></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="Tanggal Selesai" label-for="endDate">
                  <b-form-datepicker id="endDate" v-model="filter.date_2" locale="id-ID" :min="minDate"></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col sm="12">
                <b-overlay :show="isloading" rounded spinner-small spinner-variant="primary" class="d-inline-block">
                  <b-button type="submit" variant="primary">
                    <feather-icon icon="FileTextIcon"></feather-icon> Cetak PDF
                  </b-button>
                </b-overlay>
                <b-overlay :show="isloading" rounded spinner-small spinner-variant="primary" class="d-inline-block">
                  <b-button class="ml-1" @click="submitForm(false)" variant="success">
                    <feather-icon icon="FileTextIcon"></feather-icon> Cetak Excel
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormSelectMultiple from '@/my-components/FormSelectMultiple.vue'
import {BButton,BFormGroup,BFormDatepicker} from 'bootstrap-vue'
import _ from 'lodash'
import { mapFields } from 'vuex-map-fields'

export default {
  components:{
    FormSelectMultiple,
    BButton,
    BFormGroup,
    BFormDatepicker
  },
  data(){
    return {
      isloading: false
    }
  },
  computed:{
    ...mapFields('performreports',['filter']),
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    },
    idRegionalToString(){
      return _.toString(this.filter.id_regional)
    },
    userProfile(){
      return this.$store.state.auth.profile
    },
  },
  methods:{
    async submitForm(isPdf=true){
      this.isloading = true
      try {
        const params = {
          id_regional: _.join(this.filter.id_regional,','),
          id_branch: _.join(this.filter.id_branch,','),
          date_1: this.filter.date_1,
          name_regional: this.filter.name_regional,
          name_branch: this.filter.name_branch,
          date_2: this.filter.date_2,
          user_create: this.filter.user_create
        }
        if(isPdf) {
          this.$router.push({name:'view-report-perform',query:params})
        } else {
          try {
            const {data} = await this.$http.get('v1/reports/rkp_pnj',{params:params})
            const res = await this.$http.post(`${process.env.VUE_APP_REPORT_URL}/production/api/renders/rkp_pnj_excel`,data)
            const a = document.createElement("a")
            a.href = res.data
            a.download = "LAPORAN PERFORMA PENJUALAN.xlsx"
            a.click()
            this.isloading = false
          } catch (error) {
            this.isloading = false
            this.handleError(error)
          }
        }
        return Promise.resolve(true)
      } catch (error) {
        this.isloading = false
        return Promise.reject(error.response)
      }
    },
    regionalChange(e){
      this.$store.commit('performreports/SET_OPTION_REGIONAL',e)
      const d = _.map(e,'label')
      this.filter.name_regional = _.join(d,',')
      this.filter.id_branch = []
      this.filter.name_branch = ""
    },
    branchChange(e){
      this.$store.commit('performreports/SET_OPTION_BRANCH',e)
      const d = _.map(e,'label')
      this.filter.name_branch = _.join(d,',')
    },
    userChange(e){
      this.$store.commit('performreports/SET_OPTION_USER',e)
      const d = _.map(e,(n) => {
        return `'${n.label}'`
      })
      this.filter.user_create = _.join(d,',')
    },
    firstInit(){
      const now = new Date()
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
      const lastDay = new Date(now.getFullYear(), now.getMonth()+1, 0)
      if(!this.filter.date_1) this.filter.date_1 = this.$moment(firstDay).format(`YYYY-MM-DD`)
      if(!this.filter.date_2) this.filter.date_2 = this.$moment(lastDay).format(`YYYY-MM-DD`)

      if(this.isRegional) {
        this.filter.id_regional = [this.userProfile.id_regional]
        this.filter.name_regional = this.userProfile.regional_name
        this.$refs.regionalSelect.initOption([{value:this.userProfile.id_regional,label:this.userProfile.regional_name}])
      }

      if(!this.isPusat) {
        this.filter.id_branch = [this.userProfile.id_branch]
        this.filter.name_branch = this.userProfile.branch_name
        this.$refs.branchSelect.initOption([{value:this.userProfile.id_branch,label:this.userProfile.branch_name}])
        this.filter.id_user = [this.userProfile.username]
        this.filter.user_create = `'${this.userProfile.username}'`
        this.$refs.userSelect.initOption([{value:this.userProfile.username,label:this.userProfile.username}])
      }
    }
  },
  mounted(){
    this.$refs.regionalSelect.initOption(this.$store.state.performreports.filterRegionalOptions)
    this.$refs.branchSelect.initOption(this.$store.state.performreports.filterBranchOptions)
    this.$refs.userSelect.initOption(this.$store.state.performreports.filterUserOptions)
    this.firstInit()
  }
}
</script>

<style lang="scss">
  [dir] .vs--disabled .vs__dropdown-toggle, [dir] .vs--disabled .vs__clear, [dir] .vs--disabled .vs__search, [dir] .vs--disabled .vs__selected, [dir] .vs--disabled .vs__open-indicator {
    color: #7367f0;
  }
</style>